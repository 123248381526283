var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{attrs:{"label-width":70}},[(_vm.allList)?[_c('FormItem',{attrs:{"label":"出版社"}},[_c('MaterialCategorySelect',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入出版社","type":"press","allOptions":_vm.allList,"current":_vm.pressObj},on:{"update:current":_vm.onPressChange}}),_vm._v("  序号： "),_c('InputNumber',{attrs:{"placeholder":"序号"},model:{value:(_vm.pressObj.sort),callback:function ($$v) {_vm.$set(_vm.pressObj, "sort", $$v)},expression:"pressObj.sort"}})],1),_c('FormItem',{attrs:{"label":"年级"}},[_c('MaterialCategorySelect',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入年级","type":"grade","allOptions":_vm.allList,"current":_vm.gradeObj},on:{"update:current":_vm.onGradeChange}}),_vm._v("  序号： "),_c('InputNumber',{attrs:{"placeholder":"序号"},model:{value:(_vm.gradeObj.sort),callback:function ($$v) {_vm.$set(_vm.gradeObj, "sort", $$v)},expression:"gradeObj.sort"}})],1),_c('FormItem',{attrs:{"label":"书名"}},[_c('MaterialCategorySelect',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入书名","type":"book","allOptions":_vm.allList,"current":_vm.bookObj},on:{"update:current":_vm.onBookChange}}),_vm._v("  序号： "),_c('InputNumber',{attrs:{"placeholder":"序号"},model:{value:(_vm.bookObj.sort),callback:function ($$v) {_vm.$set(_vm.bookObj, "sort", $$v)},expression:"bookObj.sort"}})],1)]:_vm._e(),_c('FormItem',{attrs:{"label":"封面"}},[_c('ImgSelect',{attrs:{"imgHolders":[this.bookObj],"config":{
        file: 'file',
        key: 'cover',
        url: 'coverUrl',
        hiddeSelectWhenFull: true,
        equalProportion: true,
      },"compress":{ maxWidth: 300 }}})],1),_c('FormItem',{staticStyle:{"text-align":"right"}},[_c('Button',{staticStyle:{"margin-right":"2em"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确认")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }