<template>
  <div>
    <div class="flex m-b-sm">
      <div style="width:32px;align-self:center; font-size:20px;text-align:center">
        <slot></slot>
      </div>
      <div class="flex1 flex-middle p-r-md top-self">
        <template v-if="editingVolum">
          <MaterialVolumForm :volum="volum" @cancel="editingVolum=false" @success="onModify" />
        </template>
        <template v-else>
          <div class="flex1 m-r-md">
            <img :src="volum.coverUrl" width="82" style="border:1px solid #ddd" />
            <div>{{volum.name}}</div>
          </div>

          <Poptip confirm title="包含章节会一并删除，确定删除吗？" @on-ok="delVolum">
            <Icon
              title="删除册"
              style="width:2em"
              class="txt-btn ico text-error m-l-sm"
              type="md-remove-circle"
            />
          </Poptip>
          <button
            style="width:2em;"
            class="txt-btn ico text-primary"
            title="修改册名称"
            @click="editingVolum=true"
          >
            <Icon type="ios-create-outline" style="font-weight:bold" />
          </button>
          <div class="p-l-md" style="text-align:left">
            <div style="padding-left:16px">共有章节数：{{volum.childList.length}}</div>
            <Button type="text" class="text-primary" @click="setShowUnitsByVolumId(volum.id)">章节详情</Button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { delCategory } from './services/materialService';
import MaterialVolumForm from './MaterialVolumForm';

export default {
  components: { MaterialVolumForm },
  props: {
    volum: {}
  },
  data() {
    return {
      editingVolum: false
    };
  },
  methods: {
    setShowUnitsByVolumId(id) {
      this.$store.commit('setShowUnitsByVolumId', id);
    },
    onModify(newVolum) {
      Object.assign(this.volum, newVolum);
      this.editingVolum = false;
    },
    delVolum() {
      delCategory(this.volum).then(() => {
        this.$emit('on-del');
      });
    }
  }
};
</script>

<style scoped>
.ico {
  font-size: 1.25em;
}
.top-self {
  align-self: start;
}
::v-deep .ivu-btn-text:focus {
  box-shadow: none;
}
</style>