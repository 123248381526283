<template>
  <Card style="width: 250px">
    <Input placeholder="请输入册名" v-model="formVolum.name" class="m-b-xs" />
    <ImgSelect
      :imgHolders="[this.formVolum]"
      :config="{
        file: 'file',
        key: 'cover',
        url: 'coverUrl',
        hiddeSelectWhenFull: true,
        equalProportion: true,
      }"
      :compress="{ maxWidth: 300 }"
    />
    <div>
      <Button style="margin-right: 2em" @click="$emit('cancel')">取消</Button>
      <Button type="primary" @click="submit">确认</Button>
    </div>
  </Card>
</template>

<script>
import { saveCategory } from "./services/materialService";
export default {
  props: {
    volum: {
      required: true,
    },
  },
  data() {
    return {
      formVolum: {},
    };
  },
  methods: {
    onNameChange(newVolum) {
      this.formVolum.name = newVolum.name;
    },
    async submit() {
      if (!this.formVolum.name) {
        this.$Message.warning("册名不能为空");
        return;
      }
      if (!this.formVolum.cover && !this.formVolum.file) {
        this.$Message.warning("册封面不能为空");
        return;
      }
      if (this.formVolum.file) {
        let { key, url } = await this.$uploadService.uploadOne(
          this.formVolum.file
        );
        this.formVolum.cover = key;
        this.formVolum.coverUrl = url;
        this.formVolum.file = undefined;
      }
      saveCategory([this.formVolum]).then((res) => {
        this.$emit("success", res[0]);
      });
    },
  },
  beforeMount() {
    this.formVolum = Object.assign({}, this.volum);
  },
};
</script>

<style>
</style>