var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{staticStyle:{"width":"250px"}},[_c('Input',{staticClass:"m-b-xs",attrs:{"placeholder":"请输入册名"},model:{value:(_vm.formVolum.name),callback:function ($$v) {_vm.$set(_vm.formVolum, "name", $$v)},expression:"formVolum.name"}}),_c('ImgSelect',{attrs:{"imgHolders":[this.formVolum],"config":{
      file: 'file',
      key: 'cover',
      url: 'coverUrl',
      hiddeSelectWhenFull: true,
      equalProportion: true,
    },"compress":{ maxWidth: 300 }}}),_c('div',[_c('Button',{staticStyle:{"margin-right":"2em"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确认")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }