<template>
  <Drawer
    title="单元信息"
    :mask-closable="false"
    :value="volum.id===showUnitsByVolumId"
    width="400"
    @on-close="setShowUnitsByVolumId('')"
  >
    <div v-if="showUnitsByVolumId===volum.id" style="width:270px">
      <transition-group name="sort" tag="div">
        <MaterialUnitItem
          v-for="(unit,j) in volum.childList"
          :key="unit.id"
          :unit="unit"
          @on-del="volum.childList.splice(j, 1);"
        >
          <button v-if="j>0" class="txt-btn text-primary" @click="move(volum.childList,j-1,j)">
            <Icon type="md-arrow-round-up" />
          </button>
          <span v-else class="stuff"></span>
          <button
            v-if="j<volum.childList.length-1"
            class="txt-btn text-primary"
            @click="move(volum.childList,j,j+1)"
          >
            <Icon type="md-arrow-round-down" />
          </button>
          <span v-else class="stuff"></span>
        </MaterialUnitItem>
      </transition-group>

      <template v-if="addingUnit">
        <div @click.stop style="padding-left:35px">
          <Input
            search
            enter-button="添加"
            placeholder="输入章节名"
            v-model="newUnitName"
            @on-search="addUnit(volum)"
          />
        </div>
      </template>
      <template v-else>
        <span class="stuff"></span>
        <span class="stuff"></span>
        <button
          style="width:2em;"
          class="txt-btn ico text-success"
          title="创建新章节"
          @click.stop="newUnitName='';addingUnit=true"
        >
          <Icon type="md-add-circle" style="font-weight:bold" />
        </button>
      </template>
    </div>
  </Drawer>
</template>

<script>
import { saveCategory,resortList } from './services/materialService';
import MaterialUnitItem from './MaterialUnitItem';
import Vue from 'vue';
export default {
  components: { MaterialUnitItem },
  data() {
    return {
      addingUnit: false,
      newUnitName: ''
    };
  },
  props: {
    volumns: {
      type: Array,
      required: true
    }
  },
  computed: {
    showUnitsByVolumId() {
      return this.$store.getters.getShowUnitsByVolumId;
    },
    volum() {
      return Vue.observable(this.volumns.find((ele) => ele.id == this.showUnitsByVolumId) || { id: -1 });
    }
  },
  methods: {
    setShowUnitsByVolumId(id) {
      this.$store.commit('setShowUnitsByVolumId', id);
    },
    addUnit(parent) {
      if (!this.newUnitName.trim()) {
        this.addingUnit = false;
        return;
      }
      let unit = {
        parentId: parent.id,
        subject: this.$store.getters.getLaun,
        levelCode: 'unit',
        id: '',
        name: this.newUnitName,
        coverUrl: '',
        cover: '',
        sort:parent.childList.length
      };
      saveCategory([unit]).then((res) => {
        unit.id = res[0].id;

        if (parent.childList) {
          parent.childList.push(unit);
        } else {
          this.$set(parent, 'childList', [unit]);
        }

        this.addingUnit = false;
      });
    },
    move(list, i, j) {
      if (i < 0 || j < 0 || i >= list.length || j >= list.length) {
        return;
      }
      if (i > j) {
        let t = i;
        i = j;
        j = t;
      }
      let itemj = list.splice(j, 1)[0];
      list.splice(i, 0, itemj);
      this.$forceUpdate();
      resortList(list)
    }
  },
  beforeMount() {
    let fn = () => {
      this.addingUnit = false;
    };
    window.addEventListener('click', fn);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('click', fn);
    });
  }
};
</script>

<style scoped>
.sort-move {
  transition: transform 0.5s;
}
.stuff {
  width: 1em;
  height: 0.5em;
  display: inline-block;
}
</style>