<template>
  <div class="flex-middle" style="padding-bottom:.5em;">
    <div style="margin-right:6px;width:35px">
      <slot></slot>
    </div>
    <template v-if="editingUnit">
      <div @click.stop>
        <Input
          search
          enter-button="确定"
          placeholder="输入新章节名"
          v-model="newVolumName"
          @on-search="editUnit(unit)"
        />
      </div>
    </template>
    <template v-else>
      <div class="flex1">{{unit.name}}</div>
      <Poptip confirm title="确定删除吗？" @on-ok="delUnit(unit)">
        <Icon
          style="width:2em"
          class="txt-btn ico text-error"
          title="删除章节"
          type="md-remove-circle"
        />
      </Poptip>
      <button
        style="width:2em;"
        class="txt-btn ico text-primary"
        title="修改章节名称"
        @click.stop="newVolumName=unit.name;editingUnit=true"
      >
        <Icon type="ios-create-outline" style="font-weight:bold" />
      </button>
    </template>
  </div>
</template>  
<script>
import { delCategory, saveCategory } from './services/materialService';
export default {
  props: { unit: {} },
  data() {
    return {
      editingUnit: false,
      newVolumName: ''
    };
  },
  methods: {
    delUnit(unit) {
      delCategory(unit).then(() => {
        this.$emit('on-del');
      });
    },
    editUnit(unit) {
      if (this.newVolumName === unit.name || !this.newVolumName.trim()) {
        this.editingUnit = false;
        return;
      }
      let obj = Object.assign({}, unit, { name: this.newVolumName });
      saveCategory([obj]).then(() => {
        unit.name = this.newVolumName;
        this.editingUnit = false;
      });
    }
  },
  beforeMount() {
    let fn = () => {
      this.editingUnit = false;
    };
    window.addEventListener('click', fn);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('click', fn);
    });
  }
};
</script>

<style scoped>
.ico {
  font-size: 1.25em;
}
</style>