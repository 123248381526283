<template>
  <div class="flex">
    <div style="width:300px;" class="m-r-md">
      <img width="150" :src="info[2].coverUrl" alt="封面图片" />
      <div>{{info[0].name}}</div>
      <div>{{info[1].name}}</div>
      <div style="font-size:1.25em;padding:8px 0" @click="showInfo">{{info[2].name}}</div>
      <div>
        <Poptip confirm title="确定删除吗？" @on-ok="del">
          <button class="txt-btn text-error">删除</button>
        </Poptip>
        <Button type="text" class="text-primary" @click="$emit('editBook')">编辑</Button>
        <span class="status-span active" v-if="info[2].status=='up'">已上架</span>
        <span class="status-span" v-else>
          已下架
          <span style="padding-left:1em" v-if="info[2].remark">下架原因：{{info[2].remark}}</span>
        </span>
      </div>
    </div>

    <div>
      <transition-group name="sort" tag="div">
        <MaterialVolumItem
          v-for="(volum,i) in info[3]"
          :key="volum.id"
          :volum="volum"
          @on-del="info[3].splice(i, 1);$emit('on-del')"
        >
          <button v-if="i>0" class="txt-btn text-primary" @click="move(info[3],i-1,i)">
            <Icon type="md-arrow-round-up" />
          </button>
          <button
            v-if="i<info[3].length-1"
            class="txt-btn text-primary"
            @click="move(info[3],i,i+1)"
          >
            <Icon type="md-arrow-round-down" />
          </button>
        </MaterialVolumItem>
      </transition-group>
      <div style="padding-left:30px">
        <MaterialVolumForm
          v-if="addingVolum"
          :volum="volum"
          @cancel="addingVolum=false"
          @success="onaddVolum"
        />
        <button
          v-else
          style="width:2em;"
          class="txt-btn ico text-success"
          title="创建新的册"
          @click="addNewVolum"
        >
          <Icon type="md-add-circle" style="font-weight:bold" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { delCategory, resortList } from './services/materialService';
import MaterialVolumForm from './MaterialVolumForm';
import MaterialVolumItem from './MaterialVolumItem';
export default {
  components: { MaterialVolumForm, MaterialVolumItem },
  props: {
    info: {}
  },
  data() {
    return {
      addingVolum: false,
      newVolumName: '',
      volum: null,
      newUnitName: ''
    };
  },
  methods: {
    del() {
      delCategory(this.info[2]).then(() => {
        this.$emit('on-del');
      });
    },
    showInfo() {
      // 线上数据调试代码
      console.log(this.info[0], this.info[1], this.info[2]);
    },
    onaddVolum(e) {
      this.$emit('on-addVolum', e);
      this.info[3].push(e);
      this.addingVolum = false;
    },
    addNewVolum() {
      this.volum = {
        parentId: this.info[2].id,
        subject: this.$store.getters.getLaun,
        levelCode: 'material',
        childList: [],
        id: '',
        sort: this.info[3].length,
        name: '',
        coverUrl: '',
        cover: ''
      };
      this.addingVolum = true;
    },
    move(list, i, j) {
      if (i < 0 || j < 0 || i >= list.length || j >= list.length) {
        return;
      }
      if (i > j) {
        let t = i;
        i = j;
        j = t;
      }
      let itemj = list.splice(j, 1)[0];
      list.splice(i, 0, itemj);
      resortList(list);
    }
  }
};
</script>

<style scoped>
.ico {
  font-size: 1.25em;
}
.sort-move {
  transition: transform 0.5s;
}
</style>