<template>
  <Select
    ref="sel"
    :value="currentName"
    filterable
    filter-by-label
    allow-create
    @on-query-change="oncreate"
    @on-change="onselect"
    :placeholder="placeholder"
  >
    <Option v-for="item in options" :value="item.name" :key="item.id">{{ item.name }}</Option>
  </Select>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    placeholder: { type: String },
    current: { type: Object },
    allOptions: { type: Array },
    type: {}
  },
  data() {
    return {
      addedOption: null,
      selfAllOptions: [],
      options: []
    };
  },
  computed: {
    currentName() {
      return this.current.name;
    }
  },
  methods: {
    computeOptions() {
      // options 不能放到computed中，依赖current.parentId 和 _allOptions：
      // 经过测试在_allOptions.push(item)时，无法触发options的computed更新，所以只能这样更新了！
      if (this.current.parentId) {
        this.options = this.selfAllOptions.filter((ele) => ele.parentId === this.current.parentId);
      } else {
        this.options = this.selfAllOptions;
      }
      this.options.sort((a, b) => a.sort - b.sort);
    },
    oncreate(name) {
      if (!name) {
        return;
      }
      /**
       * 用户一旦修改输入框内容就有效，而非按enter键，或点击创建选项，所以绑定在on-query-change上
       */
      let obj = this.selfAllOptions.find((ele) => ele.name === name);
      if (!obj) {
        if (this.addedOption) {
          this.addedOption.name = name;
        } else {
          this.addedOption = Vue.observable(Object.assign({}, this.current, { id: '', name, sort: null }));
          this.selfAllOptions.push(this.addedOption);
          this.computeOptions();
        }
        obj = this.addedOption;
      }
      this.$emit('update:current', obj);

      // TODO
      // 这个是为了解决vue-design Select 插件的 bug。
      // 请注意vue-design版本变化（当前4.3.1），后续可能会引起问题。
      this.$nextTick(() => {
        this.$refs.sel.$forceUpdate();
        this.$nextTick(() => {
          this.$refs.sel.$children[0].query = name;
        });
      });
    },
    onselect(name) {
      let obj = this.selfAllOptions.find((ele) => ele.name === name);
      if (!obj) {
        return;
      }
      this.$emit('update:current', obj);
    }
  },
  beforeMount() {
    this.selfAllOptions = JSON.parse(JSON.stringify(this.allOptions.filter((ele) => this.type === ele.levelCode)));
    this.computeOptions();
  },
  watch: {
    'current.parentId'() {
      this.computeOptions();
    }
  }
};
</script>

<style>
</style>